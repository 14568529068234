import React from 'react';
import './App.scss';
import linkedin from './assets/linkedin.png';
import offerts from './assets/billog-oferts.png';
import web from './assets/web.svg';
import web_w from './assets/web_w.svg';
import monitor from './assets/monitor.svg';
import monitor_w from './assets/monitor_w.svg';
import mobile from './assets/mobile.svg';
import mobile_w from './assets/mobile_w.svg';
import design from './assets/design.svg';
import design_w from './assets/design_w.svg';
import billogteam from './assets/billog-team.png';
// import portfolio from './assets/portfolio.svg';
import mail from './assets/mail.svg';

const infoStyle = {
  display: 'flex',
};

function App() {
  return (
    <div className='main-component'>
      <div className='nav-bar'>
        <div className='nav-content'>
          <div className='nav-logo'>
            <h1>BS</h1>
          </div>
          <nav>
            <a href='#home' className='nav'>
              Home
            </a>
            <a href='#offer' className='nav'>
              Offer
            </a>
            <a href='#team' className='nav'>
              Team
            </a>
            <a href='#contact' className='nav'>
              Contact
            </a>
          </nav>
        </div>
        <div className='floating-icons'>
          {/* <a
            className='facebook icon'
            target='blank'
            href='https://billogstudio.my.canva.site/portfolio'
          >
            <img src={portfolio} alt='portfolio' />
          </a> */}
          <a className='mail icon' href='mailto:office@billogstudio.com'>
            <img src={mail} alt='mail' />
          </a>
          <a
            className='linkedin icon'
            target='blank'
            href='https://www.linkedin.com/company/billogstudio'
          >
            <img src={linkedin} alt='linkedin' />
          </a>
        </div>
      </div>
      <div className='welcome-component' id='home'>
        <div className='welcome-info'>
          <h1>Billog Studio</h1>
          <p>...kind yet skeptical software</p>
        </div>
      </div>
      <div id='offer'></div>
      <div className='component offer'>
        <h2>What we offer?</h2>
        <div className='component-box'>
          <div className='component-info'>
            <div className='box'>
              <h4>Clear creative process</h4>
              <p>
                After reviewing the requirements and determining the project
                details We are starting to prepare the scope of our activities.
                When they meet expectations, we get to work. We implement
                projects in stages to get the most out of them scheduled time to
                make any corrections and suggestions our clients.
              </p>
            </div>
            <div className='box'>
              <h4>Full implementation and support</h4>
              <p>
                In the case of mobile applications, we take care of the
                publication on Google Play and App Store platforms. We will also
                make sure that web solutions have found their place on the
                indicated server, ours or in the cloud.
              </p>
            </div>
          </div>
          <div className='mobile-img'>
            <img src={offerts} alt='offerts' />
          </div>
        </div>
      </div>
      <div className='offer-categories'>
        <div className='categories-box'>
          <div className='categories-space'>
            <div className='categories-item'>
              <div className='categories-img'>
                <img className='img-clean' src={web} alt='web' />
                <img className='img-hover' src={web_w} alt='web_w' />
              </div>
              <h5>Websites</h5>
              <p>
                Design and implementation of personalized web solutions. In the
                websites we create, we focus on transparency, optimization and
                positioning.
              </p>
            </div>
          </div>
          <div className='categories-space'>
            <div className='categories-item'>
              <div className='categories-img'>
                <img className='img-clean' src={monitor} alt='monitor' />
                <img className='img-hover' src={monitor_w} alt='monitor_w' />
              </div>
              <h5>Web applications</h5>
              <p>
                We create systems that help with sales, customer contact and
                process management. We have experience in implementing systems
                CRM and e-commerce classes.
              </p>
            </div>
          </div>
        </div>
        <div className='categories-box'>
          <div className='categories-space'>
            <div className='categories-item'>
              <div className='categories-img'>
                <img className='img-clean' src={mobile} alt='mobile' />
                <img className='img-hover' src={mobile_w} alt='mobile_w' />
              </div>
              <h5>Mobile applications</h5>
              <p>
                … and progressive. We take great care to ensure that our
                products are... Android and iOS systems were reliable and
                intuitive service.
              </p>
            </div>
          </div>
          <div className='categories-space'>
            <div className='categories-item'>
              <div className='categories-img'>
                <img className='img-clean' src={design} alt='design' />
                <img className='img-hover' src={design_w} alt='design_w' />
              </div>
              <h5>UI/UX design</h5>
              <p>
                We focus on the latest trends in visual composition. Our The
                solutions are intended to be minimalist and impeccable
                aesthetics.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id='team'></div>
      <div className='component team'>
        <h2>About us</h2>
        <div className='component-box' style={infoStyle}>
          <div className='component-info'>
            <div className='box'>
              <h4>Company</h4>
              <p>
                Since 2019, we have been implementing our clients' projects as
                well we develop our own products, gathering an active brand
                around our own user community. We already have a dozen or so on
                our account large projects and dozens of satisfied customers
              </p>
            </div>
            <div className='box'>
              <h4>Team</h4>
              <p>
                As a company, we focus on delivering the highest quality
                solutions focusing on the release of individual passions,
                character and creative potential of our employees.
              </p>
            </div>
          </div>
          <div className='component-img'>
            <img src={billogteam} alt='billogteam' />
          </div>
        </div>
      </div>
      <div className='contact-component' id='contact'>
        <h2>Contact</h2>
        <div className='contact-box'>
          <div className='card'>
            <div className='card-logo'>Billog Studio</div>
            <div className='card-contact'>
              e-mail: office@billogstudio.com
              <br />
              Bartosz Bartnicki <span>+48 796 772 803</span>
              <br />
            </div>
          </div>
          <p className='contact-text'>or find us</p>
          <div className='contact-items'>
            {/* <a
              className="contact-item"
              target="blank"
              href="https://billogstudio.my.canva.site/portfolio"
            >
              <div className="contact-icon">
                <img src={portfolio} alt="portfolio" />
              </div>
            </a> */}
            <a className='contact-item' href='mailto:office@billogstudio.com'>
              <div className='contact-icon'>
                <img src={mail} alt='mail' />
              </div>
            </a>
            <a
              className='contact-item'
              target='blank'
              href='https://www.linkedin.com/company/billogstudio'
            >
              <div className='contact-icon'>
                <img src={linkedin} alt='linkedin' />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
